import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import * as Constants from "./constants";
import thunk from "redux-thunk";

const initialState = {
	// UI state
	currentPage: Constants.PAGE_SPLASH,

	// potato state
	isHosting: false,
	ownId: "",
	hostId: "",
	peerList: {},
	permissions: {
		// true is requires signature, false is unsigned
		videoState: false,
		playlist: true,
		chat: false,
	},
	videoSource: {
		sources: {},
		subtitleSources: {},
		playlist: [],
	},
	videoAutoplay: true,
	latestAction: null,
	ready: {},

	// network state
	networkServerStatus: null,
	networkCouchStatus: null,

	audioPeerList: [],
	videoPlayer: null,
	uiDarkMode: false, // ???
	uiAutoplayToast: false,

	audio: {
		joinedAudio: false, // joined audio?
		enableIn: false, // mute mic?
		enableOut: true, // turn off audio sound?
		selectedInput: "", // device Id of selected microphone
		inputs: [], // list of microphones
		speaking: {}, // object mapping people to boolean
		volumeReduced: false, // has the volume been reduced?
		options: {
			gain: 1,
			reduction: 0.3,
		},
	},

	/**
	 * list of message blocks; each message block is:
	 * {
	 * 		type: Constants.ChatType,
	 * 		sender: ...,
	 * 		senderNick: ...,
	 * 		ts: ...,
	 * 		messages: [...],
	 * }
	 */
	chat: [],
};

let enhancer;
if (
	process.env.NODE_ENV !== "production" &&
	window.__REDUX_DEVTOOLS_EXTENSION__
) {
	console.log("using development environment");
	enhancer = compose(
		applyMiddleware(thunk),
		window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__()
	);
} else {
	console.log("this is a production build");
	enhancer = compose(applyMiddleware(thunk));
}

const store = createStore(rootReducer, initialState, enhancer);

/* { OLD VIDEO SOURCE
    type: Constants.VIDEO_SOURCE_TYPE_LOCAL,
    source: null,
    mimeType: null
} */

export default store;
