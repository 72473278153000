import { Actions, actionStateKey } from "./actions";

/* const videoSourceReducer = (state = {}, action) => {
    switch (action.type) {
        case Actions.VIDEO_SOURCE_TYPE_SET:
            return Object.assign({}, state, {
                type: action.data
            });
        case Actions.VIDEO_SOURCE_URL_SET:
            return Object.assign({}, state, {
                source: action.data
            });
    }
} */

const videoSourceSourcesReducer = (state = {}, action) => {
	switch (action.type) {
		case Actions.VIDEO_SOURCE_SOURCES_ADD:
			return Object.assign({}, state, {
				[action.data.key]: action.data.source,
			});
		case Actions.VIDEO_SOURCE_SOURCES_REMOVE:
			let cp = { ...state };
			delete cp[action.data.key];
			return cp;
		default:
			return state;
	}
};

const videoSourceSubtitleSourcesReducer = (state = {}, action) => {
	switch (action.type) {
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_ADD:
			let cp = { ...state };
			let cpSubsDict = { ...cp[action.data.key] };
			cpSubsDict[action.data.subKey] = action.data.source;
			cp[action.data.key] = cpSubsDict;
			return cp;
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_REMOVE:
			let cpr = { ...state };
			let cprSubsDict = { ...cpr[action.data.key] };
			delete cprSubsDict[action.data.subKey];
			cpr[action.data.key] = cprSubsDict;
			return cpr;
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_CLEAR:
			let cpc = { ...state };
			delete cpc[action.data.key];
			return cpc;
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_INIT:
			return Object.assign({}, state, {
				[action.data.key]: {},
			});
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_SET:
			return action.data.subtitleSources;
		default:
			return state;
	}
};

const videoSourceReducer = (
	state = {
		sources: {},
		subtitleSources: {},
		playlist: [],
	},
	action
) => {
	switch (action.type) {
		case Actions.VIDEO_SOURCE_SOURCES_ADD:
		case Actions.VIDEO_SOURCE_SOURCES_REMOVE:
			return Object.assign({}, state, {
				sources: videoSourceSourcesReducer(state.sources, action),
			});
		case Actions.VIDEO_SOURCE_PLAYLIST_SET:
			return Object.assign({}, state, {
				playlist: action.data.playlist,
			});
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_ADD:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_SET:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_REMOVE:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_INIT:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_CLEAR:
			return Object.assign({}, state, {
				subtitleSources: videoSourceSubtitleSourcesReducer(
					state.subtitleSources,
					action
				),
			});
		default:
			return state;
	}
};

const hostConnectionsReducer = (state = new Map(), action) => {
	let connectionsCopy = new Map(state);
	switch (action.type) {
		case Actions.HOST_CONNECTION_ADD:
			connectionsCopy.set(action.data.id, action.data.connection);
			return connectionsCopy;
		case Actions.HOST_CONNECTION_REMOVE:
			connectionsCopy.delete(action.data.id);
			return connectionsCopy;
	}
};

const peerListReducer = (state = new Map(), action) => {
	let peerListCopy = new Map(state);
	switch (action.type) {
		case Actions.PEER_LIST_ADD:
			peerListCopy.set(action.data.id, {
				nickname: action.data.nickname,
				ready: action.data.ready,
			});
			return peerListCopy;
		case Actions.PEER_LIST_REMOVE:
			peerListCopy.delete(action.data.id);
			return peerListCopy;
	}
};

const audioReducer = (
	state = {
		enableIn: false,
		enableOut: true,
		selectedInput: "",
		inputs: [],
	},
	action
) => {
	let audioCopy = { ...state };
	switch (action.type) {
		case Actions.AUDIO_JOINED_AUDIO_SET:
			audioCopy.joinedAudio = action.data;
			break;
		case Actions.AUDIO_ENABLE_IN_SET:
			audioCopy.enableIn = action.data;
			break;
		case Actions.AUDIO_ENABLE_OUT_SET:
			audioCopy.enableOut = action.data;
			break;
		case Actions.AUDIO_SELECTED_INPUT_SET:
			audioCopy.selectedInput = action.data;
			break;
		case Actions.AUDIO_INPUTS_SET:
			audioCopy.inputs = action.data;
			break;
		case Actions.AUDIO_SPEAKING_SET:
			audioCopy.speaking = audioSpeakingReducer(state.speaking, action);
			break;
		case Actions.AUDIO_VOLUME_REDUCED_SET:
			audioCopy.volumeReduced = action.data;
			break;
		case Actions.AUDIO_OPTIONS_GAIN_SET:
		case Actions.AUDIO_OPTIONS_REDUCTION_SET:
			audioCopy.options = audioOptionsReducer(state.options, action);
			break;
		default:
			break;
	}
	return audioCopy;
};

const audioSpeakingReducer = (state = {}, action) => {
	let audioSpeakingCopy = { ...state };
	switch (action.type) {
		case Actions.AUDIO_SPEAKING_SET:
			audioSpeakingCopy[action.data.key] = action.data.speaking;
			break;
		default:
			break;
	}
	return audioSpeakingCopy;
};

const audioOptionsReducer = (state = {}, action) => {
	let audioOptionsCopy = { ...state };
	switch (action.type) {
		case Actions.AUDIO_OPTIONS_GAIN_SET:
			audioOptionsCopy.gain = action.data;
			break;
		case Actions.AUDIO_OPTIONS_REDUCTION_SET:
			audioOptionsCopy.reduction = action.data;
			break;
		default:
			break;
	}
	return audioOptionsCopy;
};

const rootReducer = (state = {}, action) => {
	if (actionStateKey.hasOwnProperty(action.type)) {
		if (action.type == Actions.AUDIO_PEER_LIST_SET) {
			console.trace();
		}
		let newState = { ...state };
		newState[actionStateKey[action.type]] = action.data;
		return newState;
	}
	switch (action.type) {
		/* case Actions.VIDEO_SOURCE_TYPE_SET:
        case Actions.VIDEO_SOURCE_URL_SET:
            return Object.assign({}, state, {
                videoSource: videoSourceReducer(state.videoSource, action)
            }); */
		case Actions.VIDEO_SOURCE_SOURCES_ADD:
		case Actions.VIDEO_SOURCE_SOURCES_REMOVE:
		case Actions.VIDEO_SOURCE_PLAYLIST_SET:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_ADD:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_SET:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_REMOVE:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_INIT:
		case Actions.VIDEO_SOURCE_SUBTITLE_SOURCES_CLEAR:
			return Object.assign({}, state, {
				videoSource: videoSourceReducer(state.videoSource, action),
			});
		case Actions.HOST_CONNECTION_ADD:
		case Actions.HOST_CONNECTION_REMOVE:
			return Object.assign({}, state, {
				hostConnections: hostConnectionsReducer(
					state.hostConnections,
					action
				),
			});
		case Actions.PEER_LIST_ADD:
		case Actions.PEER_LIST_REMOVE:
			return Object.assign({}, state, {
				peerList: peerListReducer(state.peerList, action),
			});
		case Actions.AUDIO_JOINED_AUDIO_SET:
		case Actions.AUDIO_ENABLE_IN_SET:
		case Actions.AUDIO_ENABLE_OUT_SET:
		case Actions.AUDIO_SELECTED_INPUT_SET:
		case Actions.AUDIO_INPUTS_SET:
		case Actions.AUDIO_SPEAKING_SET:
		case Actions.AUDIO_VOLUME_REDUCED_SET:
		case Actions.AUDIO_OPTIONS_GAIN_SET:
		case Actions.AUDIO_OPTIONS_REDUCTION_SET:
			return Object.assign({}, state, {
				audio: audioReducer(state.audio, action),
			});
		default:
			return state;
	}
};

export default rootReducer;
