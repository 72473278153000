import React from 'react';
import Spinner from "react-bootstrap/Spinner";

import "./Loading.css";

const Loading = () => {
    return (
        <div id="loading">
            <Spinner id="spinner" animation="grow" variant="primary"></Spinner>
        </div>
    )
}

export default Loading;