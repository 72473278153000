import "./utils/log";

import React from 'react';
import ReactDOM from 'react-dom';

import 'bootswatch/dist/darkly/bootstrap.min.css';
import "./scss/custom.scss";
import "./index.css"
import PotatoApp from './components/PotatoApp';
import Help from "./components/Help";

import { Provider } from 'react-redux';
import store from './store/store';

import { BrowserRouter, Route, Switch } from "react-router-dom";

/*
if (store.getState().uiDarkMode){
    import('bootswatch/dist/darkly/bootstrap.min.css').then((b) => {console.log(b)});
    import("./scss/custom.scss").then((b) => {console.log(b)});
    import("./index.css").then((b) => {console.log(b)});
}
else{
    import('bootstrap/dist/css/bootstrap.min.css').then((b) => {console.log(b)});
    import("./index.css").then((b) => {console.log(b)});
}
*/

console.log(store.getState());

if (process.env.NODE_ENV !== 'production') {
	//make maps inspectable in redux devtools: https://github.com/zalmoxisus/redux-devtools-extension/issues/124
	Map.prototype.toJSON = function () {
		var obj = {};
		this.forEach((value, key) => obj[key] = value);
		return obj;
	}
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Switch>
					<Route path="/help" component={Help} />
					<Route component={PotatoApp} />
				</Switch>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
