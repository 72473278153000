
const _log = console.log;

export function muteLogs() {
    console.log = function () { }
}

export function unmuteLogs() {
    console.log = _log;
}

if (process.env.NODE_ENV !== "production") {
} else {
    // mute logs in production environment
    muteLogs();
}