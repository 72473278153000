import React, { Suspense, lazy } from 'react';
import { connect } from "react-redux";
import './PotatoApp.css';
import * as Constants from '../store/constants';

import Loading from "./Loading";

const PageSplash = lazy(() => import("./PageSplash"));
const PageVideo = lazy(() => import("./PageVideo"));
const PageNickname = lazy(() => import("./PageNickname"));
/* import PageSplash from "./PageSplash"
import PageVideo from './PageVideo';
import PageNickname from "./PageNickname" */

const _PotatoApp = ({ currentPage }) => {
    let page;
    //return <PageVideo></PageVideo>;
    switch (currentPage) {
        case Constants.PAGE_SPLASH:
            page = <PageSplash></PageSplash>;
            break;
        case Constants.PAGE_NICKNAME:
            page = <PageNickname></PageNickname>;
            break;
        case Constants.PAGE_VIDEO:
            page = <PageVideo></PageVideo>;
            break;
        default:
            page = <p>PAGE_SPLASH</p>;
            break;
    }
    return (
        <Suspense fallback={<Loading></Loading>}>
            {page}
        </Suspense>
    );
};

const mapStateToProps = state => {
    return {
        currentPage: state.currentPage
    };
};

export default connect(
    mapStateToProps,
    {}
)(_PotatoApp);
